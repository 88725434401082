import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

// import Vuex from 'vuex'
// import store from './store/store'

// import './plugins/element.js'

import Login from './pages/Login.vue'

import {setCookie} from './utils/cookie'


import Home from './pages/Home.vue'

import {
	EventBus
} from "./event-bus.js";


// element ui 所有可引入组件名: https://github.com/ElemeFE/element/blob/master/components.json
import { Button, Select, Pagination, Table, TableColumn, Loading, Row, Col, Header, Message, Option, Image,
	Dialog, Form, FormItem, Input, Container, Link, Main, Card, MessageBox, DatePicker, Checkbox
} from 'element-ui';
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Link)
Vue.use(Main)
Vue.use(Card)
// Vue.use(MessageBox) // 该方式会弹出一个无用的窗口
Vue.component(MessageBox.name, MessageBox); //使用这种方法引用

Vue.use(Button)
Vue.use(Select)
Vue.use(Pagination)
// Vue.use(Dropdown)
// Vue.use(DropdownMenu)
// Vue.use(DropdownItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Loading)
Vue.use(Row)
Vue.use(Col)
Vue.use(Header)
Vue.use(Image)
Vue.prototype.$message = Message
Vue.use(Option)
// Vue.use(OptionGroup)
// Vue.use(Scrollbar)
Vue.use(DatePicker)

Vue.use(Checkbox)

Vue.use(VueRouter)
// Vue.use(Vuex)
Vue.config.productionTip = false
const routes = [{
		name: 'login',
		path: '/',
		component: Login
	},

	{
		name: 'home',
		path: '/',
		component: Home
	},
]

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
	// mode: 'history',
	routes: routes // (缩写) 相当于 routes: routes
})

function isAuthenticated() {
	// 返回token字符串还是Boolean值？？？ 猜想是token字符串
	return localStorage.token
}

// 
router.beforeEach((to, from, next) => {

if (!(to.name === 'login') && !isAuthenticated()) {
		next({
			'name': 'login'
		})
	} else if (to.name === 'login' && isAuthenticated()) {
		next({
			'name': 'home'
		})
	} else {
		next()
	}

})

EventBus.$on('logout', () => {
	// 进入登录页面
	setCookie("access-token", "/", ".cereb.ai")
	localStorage.removeItem('token');
	if (router.currentRoute.name !== 'login') {
		router.go({
			'name': 'login'
		});
	}
});

/*
new Vue({
	router: router,
	render: h => h(App),
}).$mount('#app')
*/
new Vue({
	el: '#app',
	router: router,
	// store,
	render: h => h(App),
})

console.log('v20210930build001');