<template>
    <div>
        <div class="wrap">
            <el-container>
                <el-header class="header" height="64px">
                    <!-- <el-image class="logo" :src="adminSetting.logo_url"> -->
                    <img class="logo" src="../assets/logo.png" />
                    <div class="logo-title">Admin Portal</div>
                    <el-link class="logout" type="info" @click="handleLogout">Log Out</el-link>
                </el-header>
                <el-main class="main">

                    <div class="table-card">
                        <!-- <div class="header-section">
                            <p class="user-title">Settings: Username in Salto KS: </p>
                            <p class="username-text">{{adminSetting.salto_username}}</p>
                            <el-button class="update-button" @click="updateButtonClick">Update</el-button>
                        </div> -->
                        <div class="divid-line"></div>
                        <div class="table-header-section">
                            <!-- <div class="table-text">Offices Owner</div> -->
                            <div class="table-text">Users</div>
                            <!-- <el-button class="create-button" type="primary" @click="createButtonClick">Create</el-button> -->
                        </div>
                        <div class="table-wrap">
                            <el-table class="table-content" :data="displayTableData" style="width: 100%"
                                :cell-style="{ 'font-size': '14px', 'font-weitht': 400, 'color': '#888888' }"
                                :header-cell-style="{ 'background': '#FAFAFA', 'font-size': '14px', 'font-weight': 500, 'color': 'rgba(0, 0, 0, 0.85)' }"
                                empty-text="Empty" v-loading="loadingUsers">
                                <el-table-column prop="id" label="ID" width="120" align="center">
                                </el-table-column>
                                <el-table-column prop="username" label="Account name" width="160">
                                </el-table-column>
                                <el-table-column prop="alias" label="Alias" width="160">
                                </el-table-column>
                                <el-table-column prop="email" label="Email" width="200">
                                </el-table-column>
                                <el-table-column prop="status" label="Status" width="130">
                                    <template slot-scope="scope">
                                        <div class="indicator-warpper">
                                            <div class="status-indicator-inactive"
                                                v-if="scope.row.status.substring(0, 7) === 'expired'"></div>
                                            <div class="status-indicator-active"
                                                v-else-if="scope.row.status === 'active'">
                                            </div>
                                            <div class="status-indicator-inactive"
                                                v-else-if="scope.row.status === 'inactive'"></div>
                                            <div class="status-indicator-pending"
                                                v-else-if="scope.row.status === 'pending'"></div>
                                            <!-- <div class="status-indicator-else" v-else></div> -->
                                            <div class="status-text">{{ getStatusText(scope.row.status) }}</div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="dataPoints" label="Max Data Points" align="center" width="160">
                                </el-table-column>
                                <el-table-column prop="creationTime" label="Creation Time" width="160">
                                </el-table-column>
                                <el-table-column prop="expirationTime" label="Expiration Time" width="160">
                                </el-table-column>
                                <el-table-column label="Operations" width="250">
                                    <template slot-scope="scope">
                                        <div class="operation-wrap">
                                            <el-button class="status-operation-button" @click="updateStatus(scope.row)"
                                                type="text" size="small">
                                                {{ getStatusButtonText(scope.row.status) }}
                                            </el-button>
                                            <div class="button-divider" />
                                            <el-button class="operation-button" @click="handleEdit(scope.row)"
                                                type="text" size="small">Edit</el-button>
                                            <div class="button-divider" />
                                            <el-button class="operation-button" @click="handleDelete(scope.row)"
                                                type="text" size="small">Delete</el-button>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column>
                                </el-table-column>
                            </el-table>
                            <div class="pagination-wrap">
                                <el-pagination class="page-index" background layout="prev, pager, next"
                                    @current-change="handleCurrentChange" :current-page.sync="currentPage"
                                    :pager-count="9" :page-size="pageSize" :total="totalItems">
                                </el-pagination>
                            </div>

                        </div>


                    </div>
                </el-main>
            </el-container>
        </div>
        <!-- <DialogEdit :dialogEditIsVisible="showEditDialog" :sites="adminSites" :initFormData="editFormData" @buttonClick="dialogEditHandle(arguments)" @errorHandling="defaultErrorHandling"></DialogEdit> -->
        <DialogEdit :dialogEditIsVisible="showEditDialog" :initFormData="editFormData" @buttonClick="dialogEditHandle"
            @errorHandling="defaultErrorHandling"></DialogEdit>
    </div>
</template>

<script>

import {
    EventBus
} from "../event-bus.js";

import { MessageBox } from 'element-ui'

// import tableLogo from '../assets/table-logo.png'
import {
    getAdminPlatformUsers, updateStatus, deleteUser
} from '../api'

import DialogEdit from '../components/DialogEdit.vue'

export default {
    components: {
        DialogEdit,
    },
    data: function () {
        return {
            showEditDialog: false,
            editFormData: {},
            // tableData: [],

            userPageData: {
                currPage: 1,
                list: [], //Users
                pageSize: 7,
                totalCount: 0,
                totalPage: 0,
            },
            adminSites: null,
            currentPage: 1,
            pageSize: 7,
            loadingUsers: false
        }
    },
    computed: {
        totalItems: function () {
            return this.userPageData.totalCount
        },
        displayTableData: function () {
            let currentPageUsers = this.deepClone(this.userPageData.list)
            // console.log(currentPageUsers)
            currentPageUsers.map(user => {
                var expirTime = new Date(user.expirationTime)
                var nowTime = new Date()
                if (expirTime < nowTime) {
                    user.status = 'expired_' + user.status
                }
                user.creationTime = this.formatTime(user.creationTime)
                user.expirationTime = this.formatTime(user.expirationTime)
                return user
            })
            console.log(currentPageUsers[0])
            return currentPageUsers
        }
    },
    methods: {
        getStatusText(text) {
            if (text.substring(0, 7) === 'expired') {
                return text.substring(0, 7)
            } else {
                return text
            }
        },

        getStatusButtonText(text) {
            if (text.substring(0, 7) === 'expired') {
                return text.substring(8) === 'active' ? 'Deactivate' : 'Activate'
            } else {
                return text === 'active' ? 'Deactivate' : 'Activate'
            }
        },

        defaultErrorHandling(err) {
            console.log(err)
            if (err === 'invalid auth') {
                this.$message.error({ center: true, message: 'invalid salto authentication' });
            } else if (err === 'unauthorized') {
                this.$message.error({ center: true, message: 'login expired' });
                this.logout()
                // } else if (err === 'username exists') {
                //     this.$message.error({center: true, message: 'username exists'});
            } else {
                this.$message.error({ center: true, message: 'network error' });
            }
        },
        handleCurrentChange() {
            this.loadUsers()
        },
        loadUsers() {
            this.loadingUsers = true
            getAdminPlatformUsers(null, this.currentPage, this.pageSize).then(userPageData => {
                // console.log(userPageData)
                this.currentPage = userPageData.currPage
                this.userPageData = userPageData
            })
                .catch(this.defaultErrorHandling)
                .finally(() => {
                    this.loadingUsers = false
                })
        },
        updateStatus(user) {
            var status
            console.log(user.status.substring(8))
            if (user.status.substring(0, 7) !== 'expired') {
                status = user.status === 'active' ? 'inactive' : 'active'
            } else {
                status = user.status.substring(8) === 'active' ? 'inactive' : 'active'
            }
            updateStatus(user.id, status).then(() => {
                this.loadUsers()
            })
        },
        handleEdit(row) {
            console.log(row)
            var index = (this.userPageData.list || []).findIndex((x) => x.id === row.id);
            var editItem = this.userPageData.list[index]

            this.editFormData.id = editItem.id;
            this.editFormData.username = editItem.username;
            this.editFormData.expirationTime = editItem.expirationTime;
            // TODO
            this.editFormData.dataPoints = editItem.dataPoints;
            this.editFormData.plan = editItem.plan;
            this.editFormData.alias = editItem.alias;
            // this.editFormData.password = editItem.password;
            // this.editFormData.status = editItem.status;

            this.showEditDialog = true;
        },
        handleDelete(row) {
            MessageBox.confirm('<div style="font-size: 15px; margin-left:12px; margin-bottom:40px;">Delete?</div>', '', {
                customClass: "msgBox",
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                dangerouslyUseHTMLString: true
            })
                .then(() => {
                    console.log('delete confirm.');
                    deleteUser(row.id).then(() => {
                        // handle success
                        this.$message.success({ center: true, message: 'success' });
                        // refresh table
                        this.loadUsers()
                    }).catch(() => {
                        this.$message.error({ center: true, message: 'fail' });
                    });
                })
                .catch(() => {
                    console.log('delete concel.');
                });
        },
        dialogEditHandle(e) {
            let type = e
            console.log('type: %s', e)
            // let type = e[0]
            // let data = e[1]
            if (type === 'cancel') {
                console.log("dialogEditHandle: cancle.")
            }
            else if (type === 'confirm') {
                console.log("dialogEditHandle: confirm.")
                this.loadUsers()
                // updateUserDetail(data).then(() => {
                //     this.$message.success('success');
                // }).catch((err) => {
                //     this.defaultErrorHandling(err)
                // }).finally(() => {
                //     this.showEditDialog = false
                //     this.loadUsers()
                // })
            }
            else if (type === 'close') {
                console.log("dialogEditHandle: close.")
            }
            this.showEditDialog = false;
        },
        handleLogout() {
            EventBus.$emit('logout');
        },
        // logout() {
        //     localStorage.removeItem('token')
        //     this.$router.push({ 'name': 'login'})
        // },

        formatTime(preTime) {
            if (preTime === null) {
                return 'null'
            }
            var t = new Date(preTime)
            var time = t.getFullYear() + '-' + (t.getMonth() + 1 < 10 ? "0" + (t.getMonth() + 1) : t.getMonth() +
                1) + '-' + (t.getDate() < 10 ? "0" + t.getDate() : t.getDate()) + ' ' + (t.getHours() < 10 ?
                    "0" + t
                        .getHours() : t.getHours()) + ':' + (t.getMinutes() < 10 ? "0" + t.getMinutes() : t
                            .getMinutes()) + ':' + (t.getSeconds() < 10 ? "0" + t.getSeconds() : t.getSeconds());
            return time
        },

        deepClone(obj) {
            var _obj = JSON.stringify(obj),
                objClone = JSON.parse(_obj);
            return objClone;
        },
    },
    mounted: function () {
        console.log('start............')

        this.loadUsers()
    }

}
</script>

<style>
/*.el-table::before {
    height: 0px;
}*/
</style>

<style scoped>
.table-content::before {
    height: 0px;
}

.indicator-warpper {
    display: flex;
    align-items: center;
}

.status-text {
    margin-left: 10px;
}

.status-indicator-active {
    width: 6px;
    height: 6px;
    background: #52C41A;
    border-radius: 50%;
}

.status-indicator-inactive {
    width: 6px;
    height: 6px;
    background: #FF4141;
    border-radius: 50%;
}

.status-indicator-pending {
    width: 6px;
    height: 6px;
    background: #CCCCCC;
    border-radius: 50%;
}

.status-indicator-else {
    width: 6px;
    height: 6px;
    background: #ffc107;
    border-radius: 50%;
}

.wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #F8FCFF;
    z-index: -2;
}

.header {
    /*position: absolute;*/
    /*width: 100%;*/
    /*height: 64px;*/
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
}

.logo {
    position: absolute;
    top: 9px;
    left: 109px;
    width: 46px;
    height: 46px;
}

.logo-title {
    position: absolute;
    /*top: -5px;*/
    height: 64px;
    line-height: 64px;
    left: 165px;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65);
    /*line-height: 27px;*/
}

.logout {
    position: absolute;
    right: 120px;
    height: 64px;
    line-height: 64px;
    /*top: 30%;*/
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 22px;
}

.main {
    /*position: absolute;*/
    width: 100%;
    /*height: 90%;*/
    /*top: 64px;*/
    padding-top: 16px;
    padding-left: 130px;
    padding-right: 130px;
}

.table-card {
    /*position: absolute;*/
    /*width: 82%;*/
    min-width: 1180px;
    /*height: 694px;*/
    /*left: 9%;*/
    /*    left: 130px;
    right: 130px;*/

    background: #FFFFFF;
    border-radius: 6px;
    padding-bottom: 38px;


}

.header-section {
    display: flex;
    align-items: center;
    height: 78px;
}

.user-title {
    /*position: absolute;*/
    margin-left: 44px;
    font-size: 15px;
    /*font-family: PingFangSC-Medium, PingFang SC;*/
    font-weight: 500;
    color: #555555;
    line-height: 22px;
}

.username-text {
    /*position: absolute;*/
    /*left: 250px;*/
    margin-left: 6px;
    font-size: 15px;
    /*font-family: PingFangSC-Medium, PingFang SC;*/
    font-weight: 500;
    color: #555555;
    line-height: 22px;

}

.update-button {
    /*position: absolute;*/
    /*left: 410px;*/
    /*top: 30px;*/
    margin-left: 37px;
    width: 88px;
    height: 36px;
    padding-top: 0;
    line-height: 36px;

    font-size: 15px;
    /*font-family: PingFangSC-Regular, PingFang SC;*/
    font-weight: 400;
    color: #555555;


}

.divid-line {
    /*position: absolute;*/
    /*top: 60px;*/
    height: 1px;
    background: #E8E8E8;

}

.table-header-section {
    position: relative;
    height: 86px;
}

.table-text {
    /*position: absolute;*/
    /*top: 100px;*/
    /*font-size: 15px;*/
    /*font-family: PingFangSC-Medium, PingFang SC;*/
    /*    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;*/
    position: absolute;
    left: 50px;
    top: 33px;
    width: 103px;
    height: 22px;
    font-size: 15px;
    /*font-family: PingFangSC-Medium, PingFang SC;*/
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;

}

.create-button {
    position: absolute;
    right: 50px;
    top: 26px;
    width: 88px;
    height: 36px;
    background: #1890FF;

    /*    width: 52px;
    height: 22px;*/
    font-size: 15px;
    /*font-family: PingFangSC-Regular, PingFang SC;*/
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    padding-top: 0;

}

.table-wrap {
    padding-left: 50px;
    padding-right: 50px;
    /*height: 445px;*/
}

.table-content {
    /*position: absolute;*/
    /*top: 166px;*/
    min-height: 445px;
}

/*.el-pager li.active {
    background: #1890FF;
}

.el-pager {
    background: #1890FF;
}
*/
.operation-wrap {
    display: flex;
    align-items: center;
}

.status-operation-button {
    width: 70px;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #1890FF;
}

.operation-button {
    /*width: 25px;*/
    /*height: 22px;*/
    font-size: 14px;
    /*font-family: PingFangSC-Regular, PingFang SC;*/
    font-weight: 400;
    color: #1890FF;
    /*line-height: 22px;*/

}

.button-divider {
    width: 1px;
    height: 14px;
    background: #E9E9E9;
    margin-left: 14px;
    margin-right: 14px;
}

.pagination-wrap {
    position: relative;
    padding-top: 27px;
    height: 29px;
}

.page-index {
    position: absolute;
    right: 0;
    /*bottom: 38px;*/
    /*right: 50px;*/


}
</style>
