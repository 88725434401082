<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  data: function() {
    return {
    }
  },
  components: {
  },
  metaInfo: {
    title: 'Admin Portal',
    // meta: [
    //   { charset: 'utf-8' },
    //   { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    // ]
  }
}
</script>

<style>
@font-face {  
 font-family: 'Helvetica Neue Provided';
 src: url('./assets/font/Helvetica-Neue.ttf');
 /*font-display: swap;*/
}
body {
  margin: 0;
}
#app {
  font-family: "Helvetica Neue","Helvetica Neue Provided",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
