<template>
  <el-dialog class='edit-user-dialog' title="Edit User" :visible.sync="dialogEditIsVisible" width="500px"
    style="text-align: left;" @open="onDialogOpen" top="110px" :before-close="handleClose">

    <el-form :model="this.formData" :rules="rules" ref="editForm" label-position="left">

      <el-form-item prop="username" label="Account Name:" :label-width="formLabelWidth" :label-position="labelPosition">
        <div style="width=240px;">{{ this.formData.username }}</div>
      </el-form-item>

      <el-form-item prop="alias" label="Alias:" :label-width="formLabelWidth" :label-position="labelPosition">
        <el-input placeholder="Type alias" v-model="formData.alias"></el-input>
      </el-form-item>

      <el-form-item label="Expiration Time:" :label-width="formLabelWidth" :label-position="labelPosition">
        <template>
          <div class="block">
            <el-date-picker v-model="expirationTimeValue" align="left" type="date" value-format="yyyy-MM-dd"
              placeholder="Please select a date" :picker-options="pickerOptions">
            </el-date-picker>
          </div>
        </template>
      </el-form-item>
      <!-- <el-form-item prop="password" label="Password:" :label-width="formLabelWidth">
                <el-input v-model="formData.password" placeholder="Password"></el-input>
            </el-form-item>
            <el-form-item prop="salto_username" label="Username in Salto KS:" :label-width="formLabelWidth">
                <el-input v-model="formData.salto_username" placeholder="Username in Salto KS"></el-input>
            </el-form-item>
            <el-form-item prop="salto_password" label="Password in Salto KS:" :label-width="formLabelWidth">
                <el-input v-model="formData.salto_password" placeholder="Password in Salto KS"></el-input>
            </el-form-item>
            <el-form-item prop="site_id" label="Site:" :label-width="formLabelWidth">
                <el-select v-model="formData.site_id" placeholder="Site in Salto KS" no-data-text="no data">
                    <el-option
                      v-for="item in sites"
                      :key="item.id"
                      :label="item.customer_reference"
                      :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="status" label="Status:" :label-width="formLabelWidth">
                <el-select v-model="formData.status" placeholder="Active">
                    <el-option key="active" label="Active" value="active"></el-option>
                    <el-option key="inactive" label="Inactive" value="inactive"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="logo_url" label="Logo Url:" placeholder="" :label-width="formLabelWidth">
                <el-input v-model="formData.logo_url"></el-input>
            </el-form-item> -->
      <el-form-item prop="dataPoints" label="Max Data Points:" placeholder="10" :label-width="formLabelWidth">
        <el-input type='number' v-model="formData.dataPoints"></el-input>
      </el-form-item>

      <el-checkbox v-for="plan in userPlanList" :label="plan" :value="plan === formData.plan" @change="onChangePlan(plan)"
        :key="plan">{{ plan }}
      </el-checkbox>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="emitClick('cancel')">Cancel</el-button>
      <el-button type="primary" @click="submitForm()" :loading='isLoading'>Confirm</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  updateUserDetail
} from '../api'
import moment from 'moment';

  var utcOffset = 8

  export default {
    props: ['dialogEditIsVisible', 'initFormData', 'sites'],
    data() {
      return {
        isLoading: false,
        formData: {},

        userDetail: {},

        userPlanList: ['normal', 'premium'],

        rules: {
          // username: [{
          //   type: 'string',
          //   required: true,
          //   message: 'Account name is required',
          //   trigger: 'blur'
          // }],
          alias: [{
            type: 'string',
            required: true,
            message: 'Alias is required',
            trigger: 'blur'
          }],
          // expirationTime: [
          //   { type: 'number', required: true, message: 'Expiration Time is required', trigger: 'blur' }
          // ],
          dataPoints: [{
            required: true,
            message: 'Max Data Points is required',
            trigger: 'blur'
          }],
        },


        formLabelWidth: '170px',
        labelPosition: 'left',


        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 24 * 3600 * 1000;
          },
          // shortcuts: [{
          //   text: 'Today',
          //   onClick(picker) {
          //     picker.$emit('pick', new Date());
          //   }
          // }, {
          //   text: 'One year later',
          //   onClick(picker) {
          //     const date = new Date();
          //     date.setTime(date.getTime() + 3600 * 1000 * 24 * 365);
          //     picker.$emit('pick', date);
          //   }
          // }]
        },
        expirationTimeValue: String,
      };
      
    },

    methods: {
      onChangePlan(plan){
        if(this.formData.plan==plan){
          return;
        }
        this.formData.plan = plan;
        // console.log('plan', plan, this.formData.plan);
      },
      defaultErrorHandling(err) {
        console.log(err)
        if (err === 'invalid auth') {
            this.$message.error({center: true, message: 'invalid authentication'});
        } else if (err === 'unauthorized') {
            this.$message.error({center: true, message: 'login expired'});
            this.logout()
        // } else if (err === 'username exists') {
        //     this.$message.error({center: true, message: 'username exists'});
        } else {
            this.$message.error({center: true, message: 'network error'});
        }
      },
      onDialogOpen() {
        this.formData = Object.assign({}, this.initFormData)
        if (this.formData.expirationTime !== null) {
          this.expirationTimeValue = this.timeConvert(this.formData.expirationTime, 'date')
        } else {
          this.expirationTimeValue = null
        }
      },
      submitForm() {
        this.$refs['editForm'].validate((valid) => {
          if (valid) {
            this.emitClick('confirm')
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      emitClick(button) {
        if (button == 'confirm') {
          this.userDetail = {}
          // console.log(this.formData)

          this.userDetail.id = this.formData.id
          this.userDetail.expirationTime = this.timeConvert(this.expirationTimeValue, 'timestamp')
          this.userDetail.dataPoints = this.formData.dataPoints
          this.userDetail.plan = this.formData.plan
          this.userDetail.alias = this.formData.alias
          console.log(this.userDetail)

          this.isLoading = true
          updateUserDetail(this.userDetail).then(() => {
            this.$message.success('success');
            this.$emit('buttonClick', button)
          }).catch((err) => {
            this.defaultErrorHandling(err)
          }).finally(() => {
            this.isLoading = false
          })
        } else {
          this.$emit('buttonClick', button)
        }

      },
      handleClose() {
        this.$emit('buttonClick', 'close')
        this.$refs['editForm'].resetFields();
      },

      timeConvert(time, toType) {
        // const moment = require('moment')
        let m = moment(time).utcOffset(utcOffset)

        if (toType === 'timestamp') {
          return m.format('x')
        } else if (toType === 'date') {
          return m.format('YYYY-MM-DD')
        }
      },
    },

    updated: function () {
      // console.log(this.expirationTimeValue)

      // console.log(this.timeConvert(1636948539000, 'date'))
      // console.log(this.timeConvert('2018-11-09', 'timestamp'))
    }
  };


</script>

<style scoped>
  .edit-user-dialog>>>.el-dialog__header {
    padding-top: 30px;
  }

  .edit-user-dialog>>>.el-dialog__body {
    padding-bottom: 0;
    padding-top: 17px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .edit-user-dialog>>>.el-dialog__footer {
    padding-bottom: 30px;
  }
</style>